import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCollection } from '@cloudscape-design/collection-hooks';

import config from "../../config";

import {
	Box,
	Button,
	Header,
	SpaceBetween,
	ButtonDropdown,
	Icon,
	CollectionPreferences,
	Pagination,
	Table,
	TextFilter,
} from "@cloudscape-design/components";

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './CustomerListTableConfig';

const CustomersList = ({ token, title }) => {
	const [customers, setCustomers] = useState([]);
	const [loading, setLoading] = useState(true);

	const navigate = useNavigate()

	useEffect(() => {
		fetch(`${config.api_endpoint}/customers`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				setCustomers(json["customers"])
				setLoading(false)
			})
			.catch((error) => console.error(error));
	}, [token]);

	const EmptyState = ({ title, subtitle, action }) => {
	  return (
	    <Box textAlign="center" color="inherit">
	      <Box variant="strong" textAlign="center" color="inherit">
	        {title}
	      </Box>
	      <Box variant="p" padding={{ bottom: 's' }} color="inherit">
	        {subtitle}
	      </Box>
	      {action}
	    </Box>
	  );
	}

	const [preferences, setPreferences] = useState({
		pageSize: 20,
		contentDisplay: [
		  { id: 'customer_code', visible: true },
		  { id: 'customer_name', visible: true },
		  { id: 'aws_account_id', visible: true },
		  { id: 'modified_on', visible: true },
		  { id: 'stacker_status', visible: true },
		  { id: 'latest_stacker_run', visible: true },
		],
	});

	const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
		customers,
		{
		  filtering: {
		    empty: <EmptyState title="Loading customers. Please wait" />,
		    noMatch: (
		      <EmptyState
		        title="No matches"
		        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
		      />
		    ),
		  },
		  pagination: { pageSize: preferences.pageSize },
		  sorting:  { defaultState: { sortingColumn: columnDefinitions[3], isDescending: true } },
		  selection: {},
		}
	);

	const { selectedItems } = collectionProps;


	const handleDropdownClick = (e) => {

		e.preventDefault()


		switch (e.detail.id) {
			case 'run':

				fetch(`${config.api_endpoint}/stacker/run`, {
					mode: "cors",
					method: "POST",
					headers: {
						"content-type": "application/json",
						"x-authorization": `Bearer ${token}`
					},
					body: JSON.stringify({'customers': selectedItems}),
				})
			.then((response) => response.json())
			.catch((error) => console.error(error));
			break

			case 'modify':
				let path = `/customers/${selectedItems[0]['customer_code']}/modify`;
    			navigate(path);
    			break

    		default:
    			break

		}

	}


	return (

		<>
			<Table
		      {...collectionProps}
		      selectionType="multi"
		      header={
	        <Header
	          counter={
	            selectedItems.length
	              ? "(" + selectedItems.length + "/" + customers.length + ")"
	              : "(" + customers.length + ")"
	          }
	          actions={
	            <SpaceBetween
	              direction="horizontal"
	              size="xs"
	            >
	              <ButtonDropdown
	              	onItemClick={handleDropdownClick}
	                items={[
	                  {
	                    text: "Modify",
	                    id: "modify",
	                    disabled: selectedItems.length > 1 ? true : false
	                  },
	                  {
	                    text: "Delete",
	                    id: "delete",
	                    disabled: selectedItems.length > 1 ? true : false
	                  },
	                  {
	                    text: "Run Stacker",
	                    id: "run",
	                    disabled: false
	                  }
	                ]}
	              >
	                Actions
	              </ButtonDropdown>

	              <Button variant="primary"
	              	onClick={() => {
						navigate("/customers/add");
					}}
	              >

	                Add new customer <Icon name='add-plus' />
	              </Button>
	            </SpaceBetween>
	          }
	        >
	          Customers
	        </Header>
		      }
		      columnDefinitions={columnDefinitions}
		      columnDisplay={preferences.contentDisplay}
		      items={items}
		      loading={loading}
	          loadingText="Loading customers"
		      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
		      filter={
		        <TextFilter
		          {...filterProps}
		          countText={getMatchesCountText(filteredItemsCount)}
		          filteringAriaLabel="Filter customers"
		        />
		      }
		      preferences={
		        <CollectionPreferences
		          {...collectionPreferencesProps}
		          preferences={preferences}
		          onConfirm={({ detail }) => setPreferences(detail)}
		        />
		      }
		    >

		    </Table>

		</>


	);
};

const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});

export default connect(mapStateToProps)(CustomersList);
