import { useState, useEffect } from "react";
import {
	SpaceBetween,
	Button,
	Form,
	FormField,
	Input,
	Container,
	Multiselect,
	Alert,
	Link,
	Box
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const CustomerAdd = ({ token  }) => {

	const [state, setState] = useState({});
	// const [formState, setFormState] = useState({});
	// const [variables, setVariables] = useState([]);
	// const [stacks, setStacks] = useState([]);
	// const [ selectedStacks, setSelectedStacks] = useState([]);
	const navigate = useNavigate()

	const handleSubmit = (e) => {
		e.preventDefault()

		// Swap the variable overrides into the form
        state['selectedStacks'].map((stack, stackkey) => (
            stack['templates'].map((template, templatekey) => (
                template['variables'].map((variable, variablekey) => (
                    state['selectedStacks'][stackkey]['templates'][templatekey]['variables'][variablekey]['value'] = state['variables'][template['name']][variable['name']]
                ))
            ))
        ))

		fetch(`${config.api_endpoint}/customers`, {
			mode: "cors",
			method: "POST",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`
			},
			body: JSON.stringify(state),
		})
		.then((response) => response.json())
		.then((json) => (
			navigate(`/customers/${json['customer']['customer_code']}/view`)
		))
		.catch((error) => console.error(error));
	};

	useEffect((token, variables) => {
		fetch(`${config.api_endpoint}/stacks`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
		.then((response) => response.json())
		.then((json) => {

			let variablesTmp = {}
            let stacksTmp = []
            let selectedStacksTmp = []
            let stacksByName = {}

            json["stacks"].map((stack) => {
                stack['templates'].map((template, key) => {
                    variablesTmp[template['name']] = []
                    return template['variables'].map((variable, key) => (
                        variablesTmp[template['name']][variable['name']] = variable['value']
                    ))
                })

                stack = {
                    'name': stack["name"],
                    'label': stack["pretty_name"],
                    'value': stack["name"],
                    'templates': stack['templates']
                }

                stacksByName[stack['name']] = stack
                return stacksTmp.push(stack)
            })

            setState({
                stacks: stacksTmp,
                selectedStacks: selectedStacksTmp,
                variables: variablesTmp,
                customer_name: "",
                customer_code: "",
                aws_account_id: ""
            })

			// setVariables(variables)
			// setStacks(stacks)
		})
		.catch((error) => console.error(error));
	}, [token, setState]);

	const templateUrl = "https://download-rebura-bucket.s3.eu-west-1.amazonaws.com/stacker-execution-role-template.yaml"

	const hidden_vars = [
        "OneLoginApp",
        "OneLoginSamlProvider",
        "ParentRole"
    ]

	return (
		<Container>
		<SpaceBetween size='s'>
			<Alert
		      statusIconAriaLabel="Info"
		      header="Have you deployed the role yet?"
		    >
		      Before onboarding a customer to the Stacker pipeline, please ensure the stacker deployment role has been deployed in the target AWS account.
		      <br />
		      <br />

		     Stacker is hard-coded to use the IAM role  `rebura-deploy-StackerExecutionRole` and is included in the following CloudFormation template. The stack requires no parameters to be set so it is a simple deployment.

		      <br />
		      <br />
		      <Link external href={templateUrl}>{templateUrl}</Link>

		    </Alert>

				<form onSubmit={handleSubmit}>
					<Form
						actions={
							<SpaceBetween direction='horizontal' size='xs'>
								<Button formAction='none' variant='link'>
									Cancel
								</Button>
								<Button variant='primary'>Submit</Button>
							</SpaceBetween>
						}
					>

							<SpaceBetween direction='vertical' size='s'>
								<FormField label='Customer Name'>
									<Input
										value={state["customer_name"]}
										onChange={(e) =>
											setState({
												...state,
												customer_name: e.detail.value,
											})
										}
									/>
								</FormField>

								<FormField label='Customer Code'>
									<Input
										value={state["customer_code"]}
										onChange={(e) =>
											setState({
												...state,
												customer_code: e.detail.value,
											})
										}
									/>
								</FormField>

								<FormField label='AWS Account ID'>
									<Input
										value={state["aws_account_id"]}
										onChange={(e) =>
											setState({
												...state,
												aws_account_id: e.detail.value,
											})
										}
									/>
								</FormField>

								<Multiselect
		                            selectedOptions={state.selectedStacks}
		                            onChange={({ detail }) => {
		                                setState({...state, selectedStacks: detail.selectedOptions})
		                                // enableSaveButton()
		                            }}
		                            options={state.stacks}
		                            placeholder="Choose stacks"
		                        />

								{('selectedStacks' in state) && state.selectedStacks.map((stack, key) => (

									stack['templates'].map((template, key) => (

										<>

											<Box key={"c" + key}>
												{(template['variables'].filter((vari) => !hidden_vars.includes(vari['name'])).length > 0) && <>
                                    				<hr />
													<b key={"b" + key}>Variables for template: {template['name']}</b>
													<br />
													<br />
													{template['variables'].map((variable, key) => {

														if (hidden_vars.includes(variable['name'])) {
			                                                return ''
			                                            }

														return (<FormField label={variable['name']} key={key}>
															<Input
																value={state.variables[template['name']][variable['name']]}
																onChange={(e) => {
																	state.variables[template['name']][variable['name']] = e.detail.value
																	setState({...state, variables: state.variables})
																}}
															/>
														</FormField>
														)
													})}
												</>}
										</Box>
										</>

									))

								))}

							</SpaceBetween>
					</Form>
				</form>
				</SpaceBetween>
			</Container>
	);
};

const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});


export default connect(mapStateToProps)(CustomerAdd);
