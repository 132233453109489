import { useState } from "react";
import {
	Modal,
	SpaceBetween,
	Button,
	Form,
	FormField,
	Input,
	Container,
	Icon,
	Flashbar
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";

const AddTemplateModal = ({ token, visible, setVisible, templates, setTemplates, setParentFlashMessages }) => {
	const [formState, setFormState] = useState({});
	const [variables, setVariables] = useState([]);
	const [flashMessages, setFlashMessages] = useState([]);

	const handleSubmit = (e) => {
		e.preventDefault();

		fetch(`${config.api_endpoint}/templates`, {
			mode: "cors",
			method: "POST",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`
			},
			body: JSON.stringify({
				...formState, variables: variables}),
		})
			.then((response) => response.json())
			.then((json) => {
				setTemplates(json["templates"])
				setVisible(false)
				setFormState({})
				setParentFlashMessages([
					{
						header: "Successfully created a new tempalate",
						type: "success",
						content: "Your template has been added. Dont't forget to add it to a stack",
						dismissible: true,
						dismissLabel: "Dismiss message",
						onDismiss: () => setParentFlashMessages([]),
						id: "new_lease",
					},
				])
			})
			.catch((error) => {

				console.error(error)
				setFlashMessages([
					{
						header: "Error while creating a new tempalate",
						type: "error",
						content: error.message,
						dismissible: true,
						dismissLabel: "Dismiss message",
						onDismiss: () => setFlashMessages([]),
						id: "new_lease",
					},
				])
			});
	};

	const addVariable = (e) => {
		e.preventDefault()
		variables.push({
			name: formState["newvar"],
			value: ""
		})
		console.log(variables)
		setVariables(variables)
		setFormState({ ...formState, newvar: ""})
	}

	const removeVariable = (key) => {
		variables.splice(key, 1)
		setVariables(variables)
		setFormState({ ...formState})
	}

	return (
		<Modal
			onDismiss={() => setVisible(false)}
			visible={visible}
			header='Register New Template'
		>

			<SpaceBetween size="xs">

				<Flashbar items={flashMessages} />

					<form onSubmit={handleSubmit}>
					<Form
						actions={
							<SpaceBetween direction='horizontal' size='xs'>
								<Button formAction='none' variant='link'>
									Cancel
								</Button>
								<Button variant='primary'>Submit</Button>
							</SpaceBetween>
						}
					>

						<Container>
							<SpaceBetween direction='vertical' size='s'>
								<FormField label='Name'>
									<Input
										value={formState["name"]}
										onChange={(e) =>
											setFormState({
												...formState,
												name: e.detail.value,
											})
										}
										placeholder="name me"
									/>
								</FormField>

								<FormField label='Template Path' constraintText="Path in the rebura-cfn-templates repository for this template, with no preceeding slash">
									<Input
										value={formState["template_path"]}
										onChange={(e) =>
											setFormState({
												...formState,
												template_path: e.detail.value,
											})
										}
										placeholder="templates/SubDirector/my-template.yaml"
									/>
								</FormField>

								<FormField label='Region (optional)' constraintText="Set only if stack must be deployed to a specific region">
									<Input
										value={formState["region"]}
										onChange={(e) =>
											setFormState({
												...formState,
												region: e.detail.value,
											})
										}
										placeholder="us-east-1"
									/>
								</FormField>

								<FormField label='Notification ARNs (optional)' constraintText="SNS Topic ARNs, comma-delimited." >
									<Input
										value={formState["notification_arns"]}
										onChange={(e) =>
											setFormState({
												...formState,
												notification_arns: e.detail.value,
											})
										}
										placeholder="arn:aws:sns:eu-west-1:123412341234:catch-my-cloudformation-events"
									/>
								</FormField>

								{variables.map((variable, key) => (
									<FormField label={"- VARIABLE: " + variable['name']} key={key}>
									<SpaceBetween direction='horizontal'>

											<Input
											value={variables[key]['value']}
											onChange={(e) => {

												variables[key]['value'] = e.detail.value
												setVariables(variables)
												setFormState({
													...formState
												})
											}}
											placeholder="set a default"
										/>
									<Button
										onClick={(e) => {
											e.preventDefault()
											removeVariable( key)

										}}
									>
										<Icon name='subtract-minus' />
									</Button>
									</SpaceBetween>
									</FormField>
								))}

								<FormField>

									<SpaceBetween direction='horizontal'>
									<Input
										value={formState["newvar"]}
										onChange={(e) =>
											setFormState({
											...formState,
											newvar: e.detail.value,
										})
										}
										placeholder="Add a variable"
									/>
									<Button
										onClick={addVariable}
									>
										<Icon name='add-plus' />
									</Button>
									</SpaceBetween>

								</FormField>

							</SpaceBetween>

						</Container>

					</Form>

				</form>

			</SpaceBetween>

		</Modal>
	);
};

export default connect()(AddTemplateModal);
