import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
    Container,
    Header,
    SpaceBetween,
    ColumnLayout,
    Button,
    StatusIndicator,
    Flashbar,
    Tabs
 } from "@cloudscape-design/components";
import { useParams } from "react-router-dom";

import config from "../../config";

import {
    ValueWithLabel,
    formatTimestamp
} from "../../util";

import StackerConfigDisplay from "./StackerConfigDisplay";
import StackerExecutionHistory from "./StackerExecutionHistory";

const Customer = ({ token }) => {
    const [customer, setCustomer] = useState();
    let { customer_code } = useParams();
    const [state, setState] = useState({});
    const [flashMessages, setFlashMessages] = useState([]);

    const [runStackerButtonLoading, setRunStackerButtonLoading] = useState(false);
    const [runStackerButtonText, setRunStackerButtonText] = useState('Run Stacker');
    const [runStackerButtonDisabled, setRunStackerButtonDisabled] = useState(true);

    const pendingMessage = useRef({
            header: "Pending changes to apply",
            type: "warning",
            content: "Stacker still has changes to apply. When saving a customer, Stacker does not automatically run. You must press the 'Run Stacker' button",
            dismissible: false,
            id: "stacker_pending",
        })

    useEffect(() => {
        fetch(`${config.api_endpoint}/customers/${customer_code}`, {
            mode: "cors",
            method: "GET",
            headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`,
            },
        })
        .then((response) => response.json())
        .then((json) => {
            setCustomer(json['customer'])

            console.log(pendingMessage)

            switch (json['customer']['stacker_status']) {


                case 'Pending':
                    setFlashMessages([pendingMessage.current])
                    setRunStackerButtonLoading(false)
                    setRunStackerButtonDisabled(false)
                    break

                case 'Failed':
                    setFlashMessages([
                        {
                            header: "Stacker failed to run the last time",
                            type: "error",
                            content: "The most recent attempt to run Stacker failed. View the latest execution history for details.",
                            dismissible: false,
                            id: "stacker_pending",
                        },
                    ])
                    setRunStackerButtonLoading(false)
                    setRunStackerButtonDisabled(false)
                    break


                case 'Queued':
                case 'Running':
                    setRunStackerButtonLoading(true)
                    setRunStackerButtonText("Running Stacker")
                    setRunStackerButtonDisabled(true)

                    setFlashMessages([
                        {
                            header: "Stacker Running",
                            type: "info",
                            loading: false,
                            content: "Stacker is currently running for " + json['customer']['customer_name'] + ". Keep an eye on the Slack channel for progress updates or view the latest execution history for details.",
                            dismissible: true,
                            id: "stacker_running",
                        },
                    ])
                break

                default:
                    setRunStackerButtonLoading(false)
                    setRunStackerButtonDisabled(false)
                    break
            }



        })
        .catch((error) => console.error(error))

    }, [token, customer_code, pendingMessage]);

    const runStacker = (e) => {
        e.preventDefault()

        setRunStackerButtonLoading(true)
        setRunStackerButtonDisabled(true)
        setRunStackerButtonText('Running Stacker')

        // Swap the variable overrides into the form
        state['stacks'].map((stack, stackkey) => (
            stack['templates'].map((template, templatekey) => (
                template['variables'].map((variable, variablekey) => (
                    state['stacks'][stackkey]['templates'][templatekey]['variables'][variablekey]['value'] = state['variables'][template['name']][variable['name']]
                ))
            ))
        ))

        fetch(`${config.api_endpoint}/stacker/run`, {
            mode: "cors",
            method: "POST",
            headers: {
                "content-type": "application/json",
                "x-authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                customers: [
                    customer
                ]
            }),
        })
        .then((response) => response.json())
        .then((json) => {
            console.log(json)
            setFlashMessages([
                {
                    header: "Stacker Running",
                    type: "info",
                    content: "Stacker is now running for " + customer['customer_name'] + ".\n\nExecution ID: " + json[customer['customer_code']],
                    dismissible: false,
                    id: "stacker_run",
                },
            ])
        })
        .catch((error) => console.error(error));


    };

    const getStatusIndicator = (status) => {
        switch (status) {
          case "Pending":
            return "warning";
          case "Queued":
          case "Running":
            return "in-progress";
          case "Completed":
            return "success";
          default:
            return "error";
        }
      };

    return (
        <SpaceBetween size='s'>

        <Flashbar items={flashMessages} />

        {customer &&

            <Container header={
                <Header variant='h1'>
                    {customer['customer_name']}
                </Header>
            }>

                <ColumnLayout columns={6} variant='text-grid' minColumnWidth='100'>
                    <ValueWithLabel label='AWS Account ID'>
                        {customer["aws_account_id"]}
                    </ValueWithLabel>

                    <ValueWithLabel label='Customer Code'>
                        {customer['customer_code']}
                    </ValueWithLabel>

                    <ValueWithLabel label='Last Modified By'>
                        {customer['modified_by']}
                    </ValueWithLabel>

                    <ValueWithLabel label='Last Modified On'>
                        {formatTimestamp(customer['modified_on'])}
                    </ValueWithLabel>

                    <ValueWithLabel label='Stacker Status'>
                        <StatusIndicator type={getStatusIndicator(customer["stacker_status"])}>
                            {customer["stacker_status"]}
                        </StatusIndicator>
                    </ValueWithLabel>

                    <Button onClick={runStacker} loading={runStackerButtonLoading} disabled={runStackerButtonDisabled}>{runStackerButtonText}</Button>

                </ColumnLayout>

                <hr />
                <br />


                <Tabs
                  tabs={[
                    {
                      label: "Stacker Configuration",
                      id: "stacker_config",
                      content: <StackerConfigDisplay customer={customer} setCustomer={setCustomer} state={state} setState={setState} setFlashMessages={setFlashMessages} pendingMessage={pendingMessage}/>
                    },
                    {
                      label: "Stacker Execution History",
                      id: "stacker_history",
                      content: <StackerExecutionHistory customer={customer} state={state} setState={setState}/>
                    }
                  ]}
                />





            </Container>

        }
        </SpaceBetween>
    );
};

const mapStateToProps = (state) => ({
    token: state.token.accessToken,
});

export default connect(mapStateToProps)(Customer);
