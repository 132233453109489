import { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useCollection } from '@cloudscape-design/collection-hooks';

import config from "../../config";

import {
	Box,
	Button,
	Header,
	SpaceBetween,
	Icon,
	CollectionPreferences,
	Pagination,
	Table,
	TextFilter,
	Flashbar,
	Alert,
	Link

} from "@cloudscape-design/components";

import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps } from './StacksListTableConfig';


import AddStackModal from "./AddStackModal";

const StackList = ({ token, title }) => {
	const [stacks, setStacks] = useState([]);
	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [flashMessages, setFlashMessages] = useState([]);

	const EmptyState = ({ title, subtitle, action }) => {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: 's' }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  const [preferences, setPreferences] = useState({
    pageSize: 20,
    contentDisplay: [
      { id: 'name', visible: true },
      { id: 'templates', visible: true }
    ],
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    stacks,
    {
      filtering: {
        empty: <EmptyState title="No stacks" />,
        noMatch: (
          <EmptyState
            title="No matches"
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: preferences.pageSize },
      sorting:  { defaultState: { sortingColumn: columnDefinitions[0], isDescending: false } },
      selection: {},
    }
  );

  const { selectedItems } = collectionProps;

	useEffect(() => {
		fetch(`${config.api_endpoint}/stacks`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => {
				setStacks(json["stacks"])
				setLoading(false)
			})
			.catch((error) => console.error(error));
	}, [token]);

	return (
		<SpaceBetween size="xs">
			<AddStackModal
        token={token}
        visible={visible}
        setVisible={setVisible}
        stacks={stacks}
        setStacks={setStacks}
        setParentFlashMessages={setFlashMessages}
       />

      <Flashbar items={flashMessages} />

      <Alert
	      statusIconAriaLabel="Info"
	      header="What are Stacks?"
	    >
	      Stacks are groups of one or more templates. Sometimes we may need to use multiple CloudFormation templates together to configure customer accounts.

	      <br />
	      <br />

	      When creating a stack, you must select which templates are included in it plus confirm any variables that are inherited from the templates. If a template has a default value and you change it, your new value will be used.

	      <br />
	      <br />

	      Once you've created your stack, create or update a <Link external href="/customers">Customer</Link> to use your stack.
	    </Alert>

			<Table
        {...collectionProps}
        selectionType={false}
        header={
	        <Header
	          counter={
	            selectedItems.length
	              ? "(" + selectedItems.length + "/" + stacks.length + ")"
	              : "(" + stacks.length + ")"
	          }
	          actions={
	            <SpaceBetween
	              direction="horizontal"
	              size="xs"
	            >
	              <Button
	                    onClick={() => {
	                        setVisible(true);
	                    }}
	                >
	                    Create new Stack <Icon name='add-plus' />
	                </Button>
	              </SpaceBetween>
	          }
	        >Stacks
	        </Header>
        }
        columnDefinitions={columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        items={items}
        loading={loading}
        loadingText="Loading stacks"
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringAriaLabel="Filter stacks"
          />
        }
        preferences={
          <CollectionPreferences
            {...collectionPreferencesProps}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
          />
        }
	     >
	     </Table>
		</SpaceBetween>
	)
}

const mapStateToProps = (state) => ({
	token: state.token.accessToken,
});

export default connect(mapStateToProps)(StackList);
