import { useState, useEffect } from "react";
import {
	Modal,
	SpaceBetween,
	Button,
	Form,
	FormField,
	Input,
	Container,
	Multiselect,
	Flashbar
} from "@cloudscape-design/components";

import config from "../../config";
import { connect } from "react-redux";

const AddStackModal = ({ token, visible, setVisible, stacks, setStacks, setParentFlashMessages }) => {
	const [formState, setFormState] = useState({});
	const [variables, setVariables] = useState([]);
	const [templates, setTemplates] = useState([]);
	const [ selectedTemplates, setSelectedTemplates] = useState([]);
	const [flashMessages, setFlashMessages] = useState([]);

	const handleSubmit = (e) => {
		e.preventDefault();

		fetch(`${config.api_endpoint}/stacks`, {
			mode: "cors",
			method: "POST",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
			body: JSON.stringify(formState),
		})
		.then((response) => response.json())
		.then((json) => {
			setStacks(json["stacks"])
			setVisible(false)
			setFormState({})
			setParentFlashMessages([
					{
						header: "Successfully created a new stack",
						type: "success",
						content: "Your stack has been added. It can now be selected when creating or updating customers",
						dismissible: true,
						dismissLabel: "Dismiss message",
						onDismiss: () => setParentFlashMessages([]),
						id: "new_lease",
					},
				])
		})
		.catch((error) => {
				console.error(error)
				setFlashMessages([
					{
						header: "Error while creating a new stack",
						type: "error",
						content: error.message,
						dismissible: true,
						dismissLabel: "Dismiss message",
						onDismiss: () => setFlashMessages([]),
						id: "new_lease",
					},
				])
			});
	};

	useEffect(() => {
		fetch(`${config.api_endpoint}/templates`, {
			mode: "cors",
			method: "GET",
			headers: {
				"content-type": "application/json",
				"x-authorization": `Bearer ${token}`,
			},
		})
			.then((response) => response.json())
			.then((json) => {

				setTemplates(json["templates"].map((template) => {

					template.variables.map((variable, key) => {

						variables[variable['name']] = variable['value']
						return setVariables(variables)

					})

					return {
						'name': template["name"],
						'label': template["name"],
						'value': template["name"],
						'description': template["template_path"],
						'variables': template['variables']
					}
				}))
			})
			.catch((error) => console.error(error));
	}, [token, variables]);

	return (
		<Modal
			onDismiss={() => setVisible(false)}
			visible={visible}
			header='Create New Stack'
		>

			<SpaceBetween size="xs">

				<Flashbar items={flashMessages} />

				<form onSubmit={handleSubmit}>
					<Form
						actions={
							<SpaceBetween direction='horizontal' size='xs'>
								<Button formAction='none' variant='link'>
									Cancel
								</Button>
								<Button variant='primary'>Submit</Button>
							</SpaceBetween>
						}
					>
						<Container>
							<SpaceBetween direction='vertical' size='s'>
								<FormField label='Name'>
									<Input
										value={formState["name"]}
										onChange={(e) =>
											setFormState({
												...formState,
												name: e.detail.value,
											})
										}
										placeholder="name me"
									/>
								</FormField>

								<Multiselect
							      selectedOptions={selectedTemplates}
							      onChange={({ detail }) => {
							        setSelectedTemplates(detail.selectedOptions)
							        setFormState({
										...formState,
										templates: detail.selectedOptions,
									})
							    }
							      }
							      options={templates}
							      placeholder="Choose templates"
							    />



								{selectedTemplates.map((template, key) => (

									<Container header={"Variables for template: " + template['name']}>
									{template['variables'].map((variable, key) => (

										<FormField label={variable['name']}>
											<Input
												value={variables[variable['name']]}
												onChange={(e) => {
													variables[variable['name']] = e.detail.value
													setVariables(variables)
														setFormState({
														...formState
													})
												}}
											/>
										</FormField>

										))}
									</Container>
								))}

							</SpaceBetween>
						</Container>
					</Form>
				</form>
			</SpaceBetween>
		</Modal>
	);
};

export default connect()(AddStackModal);
